.containerBox {
    position: relative;
    display: inline-block;
    margin-left: 10%;
    width: 80%;
}
.text-box {
    position: absolute;
    height: 100%;
    text-align: end;
    width: 100%;
    padding-right: 10%;
}
.text-box:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: bottom;
}
.title-image h4 {
    display: inline-block;
    font-size: 80px; /*or whatever you want*/
    color: white;
}

@media (max-width:650px){
    .title-image h4 {
        font-size: 2em;
    }

}


.title-image img {
    display: block;
    width: 100%;
    height: auto;
}