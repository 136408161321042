.bg-custom1 {
    background-color: #2e3e50; /*first background color*/
}

.bg-custom2 {
    background-color: #ad9e90; /*second background color*/
}

.team-block .card-wrapper {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.team-block .card-img-top {
    display: block;
    margin: 10% 25%;
    width: 50%;
}

.team-block .row {
    margin-right: 0;
    margin-left: 0;
}

/* New Team */
.team {

}

.team .row {
    min-height: 600px;
    margin-right: 0 !important;
}

.team-text {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    margin-bottom: 20px;
    vertical-align: text-bottom;
}

.team img {
    padding-right: 10%;
    max-height: 600px;
    max-width: 100%;
}

.team-mobile .row {
    margin-right: 0;
    margin-left: 0;
}