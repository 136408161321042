.opening {
    margin-top: -15%;
    padding: 10% 10% 0 10%;
}

.opening h4 {
    font-weight: bold;
    font-size: 30px;
}

.opening .subheading {
    font-size: 20px;
}

.opening .row {
    margin-top: 10%;
}

.opening-image {
    text-align: center;
    padding: 0;

}

.opening-image img {
    width: 40%;
}
