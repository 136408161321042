.contact{
    margin-top: 5%;
    max-width: 90%;
    padding-left: 10%;

}
/*
.contact .title{
    color: #2e3e50;
}

 */

form button {
    color: white;
    background-color: #2e3e50;
    width: 10%;
}
@media (max-width:992px){
    form button {
        margin-bottom: 30px;
    }
}

.contact-block {
    background-color: #054678;
    color: white;
    text-align: center;
    height: 100%;


}

.contact-block h4{
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-block .title-wrapper{
    vertical-align: middle;
    padding-top: 15%;
    padding-bottom: 15%;

}

.contact-block h2{
    margin-right: 5px;
    margin-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.contact .btn-primary {
    width: 100%;
    background-color: #054678;
    color: whitesmoke;
}