.article {
    padding-left: 10%;
    padding-right: 10%;
}

.article img {
    width: 100%;
    max-height: 50vh;
}

.article .title {
    padding-left: 0% !important;
    font-size: 60px;
}

.article .sub-title {
}

.article p{
    margin-top: 50px;
}