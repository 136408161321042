.title {
    color: #2e3e50; /* title color */
}

.secondary-title {
    color: #1b95bb; /* secondary title color */
}

@media (max-width: 992px) {
    .content {
        margin-top: 60px;
    }
}
.tooltip-inner {
    color: white !important;
    background-color: dimgrey !important;
    width: 50% !important;
}

/* style for carousel */
.carousel{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}
@media (max-width: 992px) {
    .carousel{
        margin-top: 90px;

    }
}


.awssld {
    --slider-height-percentage: 35% !important;
    --control-bullet-color: #2e3e50 !important;
    --control-bullet-active-color: #054678 !important;
    --organic-arrow-thickness: 8px !important;
    --loader-bar-color: #2e3e50 !important;

}

/*
.about-us-video .react-player{
    width: 90% !important;
}

 */


.language-btn{
    display:block;
    height: 50px;
    width: 80px;
    borderRadius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.american-flag {
    background-image: url("images/Uk-Flag.jpg");
}

.french-flag {
    background-image: url("images/france-flag-png-large.jpg");
}


/*
border for team img
presentation button add something to know we clicked on it
add img to investkal

color: #6c757d!important;

 */

.react-player iframe{
    height: 35vw;
    width: 107.5%;
}

