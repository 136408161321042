.title-box {
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: #f5f5f5;
    text-align: center;
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;

}

.title-box h1 {
    border-left: 6px solid #1b95bb;
    padding-left: 5px;
    color: #2e3e50;
}

.not-middle {
    justify-content: left !important;
    padding-left: 10%;
}


.middle {
    justify-content: center !important;
    margin-top: +10%;
}
