
footer {
    width: 100%;
    background-color: #263238;
    margin-top: 5% !important;
    padding: 10px 0px 25px 0px;
}

footer p {
    font-size: 13px !important;
    color: #CCC;
    padding-bottom: 0px;
    margin-bottom: 8px;
}

footer .info {
    display: flex;
    justify-content: space-evenly;
}

footer .info p {
    margin-right: 20px;
}


footer .info p svg{
    margin-right: 5px;
}

@media (max-width: 771px) {
    footer .info {
        display: revert;
    }
    .text-center{
        margin-top: 20px;
    }
}