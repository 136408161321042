.CopyPortfolio .title {
    vertical-align: middle;
    padding-left: 10%;
    margin-top: 30px;
    margin-bottom: 10px;
}

.CopyPortfolio img{
    margin-top: 5%;
    padding-left: 10%;
    max-width: 80%;
}

