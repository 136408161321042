/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 112px; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 100; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #054678; /* Navy Blue Blacks*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 10px; /* Place content 10px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.nav-help-text {
    font-size: 20px;
}


/* The navigation menu links will be centered */
div ul {
    margin-top: 5%;
}

/* The navigation menu link styles */
.nav-bar div li { /*show them*/
    padding: 20px 20px 20px 200px;
    font-weight: 700;
    text-transform: capitalize;
    text-decoration: none;
    font-size: 3.5rem;
    color: #818181;
    display: block;
    transition: 0.3s;
}

li a {
    text-decoration: none;
    transition: 0.3s;
    color: #818181;
    text-underline: none;


}

/* When you mouse over the navigation links, change their color */
ul li:hover {
    color: #f1f1f1;
}

ul li a:hover {
    color: #f1f1f1;
    text-decoration: none !important;
}


/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

/* fix navbar padding  on small screens */
@media (min-width: 992px) {
    .content {
        margin-left: 112px;
    }
}

@media (max-width: 450px) {
    .content {
        margin-top: 60px;
    }
}

.d-lg-none .bg-dark {
    background-color: #054678 !important;
}

.nav-bar-icon {
    font-size: 50px;
    margin-left: 30px;
    position: fixed;
    bottom: 30px;
}
