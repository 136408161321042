.presentation {

}

.presentation .title {
    vertical-align: middle;
    padding-left: 10%;
}

.presentation .secondary-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
}

.image-place-holder {
    height: 100px;
    width: 100%;
    background-color: #1b95bb;
    margin-left: 10%;
    max-width: 80%;
}



#tooltip-top > .tooltip-inner {
    max-width: none;
    width: 30%;
}

#tooltip-top > .tooltip-arrow {
}



.text {
    font-size: 20px;
    margin-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}

.text-divided {
    font-size: 20px;
    padding-left: 10%;
    padding-right: 10%;

}

.text-buttons {
    background-color: #054678;
    width: 80%;
    margin-left: 10%;
    min-height: 10vh;
    font-size: 2vh;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

}


.text-buttons a {
    width: 100%;
    color: white;
    background-color: #054678;
    text-align: center;
    padding: 0 10%;

}

@media (max-width: 500px) {
    .text-buttons a {
        padding: 5% 0 !important;
    }
}

.text-buttons a:not(:last-child){
    border-right: 2px solid #FFFFFF;
}

.text-buttons a:hover {
    cursor: pointer;
    color: white;
    font-weight: 700;
}

.history-a {
    font-size: 15px;
}

.underline {
    font-weight: 900;
}
