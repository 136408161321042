
*,
*::before,
*::after {
    box-sizing: border-box;
}


.btn {
    background-color: white;
    border: 1px solid #cccccc;
    color: #696969;
    padding: 0.5rem;
    text-transform: lowercase;
}

.btn--block {
    display: block;
    width: 100%;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.cards__item {
    display: flex;
    padding: 1rem;
}

@media (min-width: 40rem) {
    .cards__item {
        width: 50%;
    }
}

@media (min-width: 56rem) {
    .cards__item {
        width: 33.3333%;
    }
}

.card {
    background-color: white;
    text-align: center;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.card:hover .card__image {
    filter: contrast(100%);
}

.card__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    max-height: 100px;
}

.card__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    filter: contrast(70%);
    overflow: hidden;
    position: relative;
    transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
}

.card__image::before {
    content: "";
    display: block;
    padding-top: 56.25%;
}

@media (min-width: 40rem) {
    .card__image::before {
        padding-top: 66.6%;
    }
}

.card__image--one {
    background-image: url("../images/key_innovation.jpg");
}

.card__image--two {
    background-image: url("../images/key_credibilite.jpg");
}

.card__image--three {
    background-image: url("../images/key_simplicite.jpg");
}

.card-noImage {

}

.card__title {
    color: #2e3e50;
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: 400 !important;
}

.card__text {
    flex: 1 1 auto;
    font-size: 1.1vw;
    color: #92979b !important;
    visibility: hidden;
    line-height: 1.5;
    margin-bottom: 1.25rem;


}

.mobile-card {
    color: white;
    height: 60vh;
    display: flex;

}

.mobile-card p{
    margin-top: auto;
    margin-bottom: auto;
}

.card__image--one-mobile {
    background-image: url("../images/key_innovation_m.jpg");
}

.card__image--two-mobile {
    background-image: url("../images/key_credibilite_m.jpg");
}

.card__image--three-mobile {
    background-image: url("../images/key_simplicite_m.jpg");
}

@media (max-width: 992px) {

    .cards {

    }

    .cards__item {
    }
    .card__image::before {
        content: none;
    }
    .card__image::before{
        filter: brightness(50%);
    }
    .card__image {
        filter: none;
    }
}





