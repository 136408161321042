.label-fintec {

}
.label-fintec .row {
    margin-right: 0 !important;
}

.square-cont {
    display: flex;
    flex-direction: row;
    padding-left: 15%;
}

.square {
    background: #ad9e90; /* Beige */
    vertical-align: middle;
    margin: 10px; /* margin between blocks */

    width: 300px;
    height: 300px;
}

.square-2 {
    background-color: #054678;
}

.square{
    display: flex;
    justify-content: center;
    align-items: center;
}

.square p {
    font-size: 30px;
    color: white;

    text-align: center !important;
}

.square-img-1 {
    background-image: url("../images/square_fintec.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}


.square-img-2 {
    background-image: url("../images/square_AI.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.label-fintec-text {
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
}

.label-fintec .img-link{
    color: white;
}

.video-square:hover{
    background-image: url("../images/video_img.png") !important;
    color: black !important;
    background-repeat: no-repeat;
    background-size: cover;
}
