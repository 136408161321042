.article-box{
    width: 80%;
    margin-left: 10%;
    border: 2px solid #054678;
    margin-bottom: 20px;
}
.article-box-big {
    display: flex;
}
.article-box img{
    width: 20%;
    margin: 10px;
}

.article-box-big div{
    margin: 10px;
    width: 60%;
}

.article-box h1{
    padding-bottom: 10px;
}

.article-box button{
    width: 15%;
    min-width: 90px;
    background-color: #054678;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 20px;

    align-self: flex-end;
    color: white;
}

.article-box-small div{
    margin: 10px;
}

.article-box-small .button-wrapper{
    text-align: right;
}
.News-Wrapper{
    margin-top: 5%;
}

.article-box .small-img{
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
}