.landing img{
    float: right;
}

.landing .logo-wrapper{
    height: 15vh;
}

.landing .logo-wrapper img{
    max-height: 15vh;
}
.header{
    background-image: url("../images/altia_wallpaper.jpg");
    min-height: 85vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-wrapper{
    position: absolute;
    top: 90%;
    left: 15%;
    width: 100%;
    font-size: 60px;
    color: white;

}
